.conversation-list {
    list-style: none;
    padding: 0 15px;
}

.conversation-list  dl, ol, ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

.conversation-list li {
    margin-bottom: 20px;
}

.conversation-list .chat-avatar {
    float: left;
    text-align: center;
    width: 72px;
}

.conversation-list .odd .chat-avatar {
    float: right!important;
}

.conversation-list .odd .conversation-text {
    float: right!important;
    margin-right: 12px;
    text-align: right;
    width: 70%!important;
}
.conversation-list .odd .ctext-wrap {
    background-color: #FEF5E4;
}

.conversation-list .chat-avatar i {
    font-size: 12px;
    font-style: normal;
}

.conversation-list .conversation-text {
    float: left;
    font-size: 12px;
    margin-left: 12px;
    width: 70%;
}

.conversation-list .odd .ctext-wrap:after {
    border-color: transparent;
    border-left-color: #FEF5E4;
    border-top-color: #FEF5E4;
    right: -10px!important;
    left: auto;
}

.conversation-list .ctext-wrap {
    background: #F1F3FA;
    border-radius: 3px;
    display: inline-block;
    padding: 12px;
    position: relative;
}

.conversation-list .ctext-wrap:after {
    left: -10px;
    top: 0;
    border-width: medium;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-left: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-top: 6px solid transparent;
    border-top-color: #F1F3FA;
    margin-right: -1px;
    border-right: 6px solid transparent;
    border-right-color: #F1F3FA;
}

.ReactModal__Overlay--after-open {
	z-index: 4000 !important;
}

div.note {
    height: 180px;
    width: 200px;
    background-color: #feff9c;
    margin: 2px 0;
    position: absolute;
    cursor: -webkit-grab;
    -webkit-box-shadow: 5px 5px 15px 0 rgb(0 0 0 / 20%);
    box-shadow: 5px 5px 15px 0 rgb(0 0 0 / 20%);
    border-bottom-right-radius:60px 5px;
    z-index: 10000;
}

div.note::after {
  content:"";
  position: absolute;
  bottom: 0;
  right: -4px;
  width: 100%;
  height: 140px;
  background-image: linear-gradient(173deg, rgba(0,0,0,0) 92%,
  rgba(0,0,0,0.4) 100%);
  transform: rotate(6deg);
  z-index: -1;
  filter: blur(2px);
}

#xwrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	min-height: 100%;
}

.dialogTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.thumb img {
	object-fit: cover;
	width:100%;
	height:100%
}
.thumb {
	padding: 0 !important;
}

.grid-Cell-Left {
	background-color: #fff !important;
}
.grid-Cell-Left:hover {
	background-color: #fff !important;
}
.rst__row > .rst__rowContents > .rst__rowLabel > .rst__rowTitle > .selectedNode {
	border: 1px solid red;
}

.selectedNode {
	border: 2px solid red;
}

.defaultSearch > label {
	top: -10px !important;	
}
.defaultSearch > label + .MuiInput-formControl {
	margin-top: 0px;
}

div[role="alertdialog"] {
	background-color: #fddc6c !important;
    max-width: 400px;
    min-width: 344px;
}

div[class^="SnackbarItem-base-"], div[class*=" SnackbarItem-base-"] {
	background-color: #fddc6c ;
    max-width: 400px;
    min-width: 344px;
}
.MuiSnackbarContent-root {
	align-items: start;
}

div.MuiSnackbarContent-action {
}

.MuiSnackbarContent-action {
	align-items: start;
}


div[class^="SnackbarItem-variantInfo-"], div[class*=" SnackbarItem-variantInfo-"] {
	background-color: #008bd4 !important;
    max-width: 400px;
    min-width: 344px;
}
.snackDownloadBottom {
	background-color: #fff;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
}


.loader {
  width: 250px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-family: helvetica, arial, sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  color: rgb(229, 57, 53);
  letter-spacing: 0.2em;
}

.loader::before, .loader::after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background: rgb(229, 57, 53);
    position: absolute;
    animation: load .9s infinite alternate ease-in-out;
  }
  
 .loader::before {
    top: 0;
  }
  
.loader::after {
    bottom: 0;
  }


@keyframes load {
  0% { left: 0; height: 30px; width: 15px }
  50% { height: 8px; width: 40px }
  100% { left: 235px; height: 30px; width: 15px}
}

.react-pdf__Page {
	background-color: black;
	background-image: url(/images/raster.png);
}
.react-pdf__Page__canvas {
	margin: 0 auto;
}
.rst__rowWrapper {
	padding: 5px 5px 5px 0;
}
.rst__moveHandle
 {
	background-image : none;
	padding-top : 3px;
	text-align: center;
}
.icon-a > .rst__moveHandle::before {
	content : "\f072";
	color : black;
	font : normal normal normal 16px FontAwesome;
}
.icon-b > .rst__moveHandle::before {
	content : "\f015";
	color : black;
	font : normal normal normal 16px FontAwesome;
}
.query > .rst__moveHandle::before {
	content : "\f0ce";
	color : black;
	font : normal normal normal 16px FontAwesome;
}
.bookmarkfolder > .rst__moveHandle::before {
	content : "\f115";
	color : black;
	font : normal normal normal 16px FontAwesome;
}
.bookmark > .rst__moveHandle::before {
	content : "\f097";
	color : black;
	font : normal normal normal 16px FontAwesome;
}

.sub > .rst__moveHandle::before {
	content : "\f0e8";
	color : black;
	font : normal normal normal 16px FontAwesome;
}
.item > .rst__moveHandle::before {
	content : "\f1c0";
	color : black;
	font : normal normal normal 16px FontAwesome;
}
.MaskItemAddDialog {
	z-index: 2000 !important;
}

div[id^='menu-modi_']
{
		z-index: 1550 !important;
}
.modal {
	z-index: 1500 !important;
}
.modal-backdrop {
	z-index: 1490 !important;
}
.menu-item > .MuiPaper-root {
	background-color: transparent;
	color: white;
	box-shadow: none;
}
.menu-item > .MuiPaper-root:hover {
	background-color: #303030;	
}

.marginTop {
	margin-top: 20px;
}

.planingButtonSelect {
	background-color: transparent !important;
	border-color: transparent !important;
	color: black !important;
}

.planingButtonSelect:hover {
	background-color: rgba(0,0,0, 0.08) !important;
	border-color: rgba(0,0,0, 0.08) !important;
	color: black !important;
}

.planingSelect {
	margin-top: 26px !important;
}

.navMuiButton-root:hover {
	background-color: #fafafa !important;
}


.rowMaxWidth {
	width:100%
}

.planingCard {
	min-height: calc(100% - 80px);
	margin-left: 3px;
}

.planingCard > div > .MuiCollapse-entered, .planingCard > div > div > .MuiCollapse-wrapper {
	background-color: white;
}

.planningElement > div > .MuiIconButton-label {
	color: black;
}

.MuiIconButton-label {
	color: white;
}

.MuiPickersBasePicker-container .MuiIconButton-label {
	color: black;
}

.MuiExpansionPanelDetails-root {
	background-color: transparent;
}
.MuiExpansionPanelDetails-root {	
	margin: 20px;
	margin-top: 0px;
}


.MuiCollapse-container {
	background-color: transparent;
}

.MuiPaper-root {
	background-color: rgb(69, 81, 96);
}
.applicationIcon {
    font-size: 24px !important;
    padding: 5px;
}
.startPageTop {
    height: 180px;

}
.applicationTopbar {
    height: 70px;
}
.profilHeight {
	height: 250px;
}
.disablePaddingRight {
    padding-right: 0px !important;
}

.disablePaddingLeft {
    padding-left: 0px !important;
}

.notificationFrame {
    min-width: 300px;
}

.applicationSidebar {
    background: #455160;
	height: calc(100% - 0px);
    background: #000;
    opacity: .85;
}
.applicationSidebar {
}

.applicationSidebarImg {
	background-image: url("/images/sidebar-2.jpg");
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-position: 50%;	
}
.applicationActionTop, .applicationSidebarTop, .applicationSidebarBottom {
    height: 40px;
    line-height: 40px;
    padding-left: 10px;
    padding-top: 5px;
}

.applicationActionTop, .applicationSidebarTop, .applicationSidebarBottom {
    background: black;
    color: rgb(228, 231, 234);
}

.applicationTopbar {
    padding-top: 10px;
    padding-left: 10px;
}

.applicationTopbarItem {
    min-width: 50px;
}

.applicationActionTop {
    background: white;
    color: #2f353a;
}

.applicationAction {
    height: calc(100% - 40px);
    background: #e0e0e0;
    color: #2f353a;
    padding: 10px;
}

.applicationSidebarNav {
    height: calc(100% - 80px);
    color: white;
}

.textColorLight {
    color: white;
}
.notificationHeader {
    padding: 20px;
}
.searchRoot {
    padding: 2px 4px;
    display: flex;
    alignItems: center;
    width: 400;
}
.searchInput {
    marginLeft: 8px;
    flex: 1;
}
.searchIconButton {
    padding: 10px;
}
.searchDivider {
    width: 1px;
    height: 28px;
    margin: 4px;
}

.menu {
	    list-style: none;
	    padding-left: 0;
	    margin-bottom: 30px;
		font-family: Roboto,sans-serif;
		color: #4d5259;
		font-weight: 300;
		font-size: 13px;
		line-height: 24px;
	}
	
	.menu-submenu {
	    background-color: transparent;
	    padding-top: 1rem;
	    padding-bottom: 1rem;
	    padding-left: 0;
	    display: block;
	    list-style-type: none;
		margin-bottom: 0;
		width: 100%;
	}

	.MuiExpansionPanelDetails-root {
		padding: 0px !important;
	}
	
	.icon-left {
	    -webkit-box-ordinal-group: 2;
	    order: 1;
	    font-size: 16px;
        background-color: rgba(0,0,0,0.07);
        flex-basis: 40px;
        line-height: 40px;
        border-radius: 2px;
        font-weight: 500;
        letter-spacing: 1px;
        text-align: center;
	}	
	
	ul.menu  .menu-item a {
		text-decoration:  none;
	}
		
	ul.menu  .menu-item a:hover {
		background: #808080;
	}
	
	.menu-category {
	    position: relative;
	    font-family: Roboto,sans-serif;
	    display: block;
	    text-transform: uppercase;
	    letter-spacing: 1px;
	    font-size: 0.6875rem;
	    font-weight: 500;
	    line-height: 32px;
	    padding: 1rem 20px 0.5rem;
	    color: #fff;
	    opacity: 0.7;
	    white-space: nowrap;
	    overflow: hidden;
	    text-overflow: ellipsis;
	 }
	.menu-category::after {
	    content: '';
	    width: 100%;
	    height: 0;
	    border-top: 1px dashed #fff;
	    vertical-align: middle;
	    margin-left: 1.5rem;
	    opacity: 0.3;
	    position: absolute;
	    top: 50%;
	    margin-top: 3px;
	}	 
	.menu-item {
	    vertical-align: top;
	    -webkit-transition: opacity 0.2s linear;
	    transition: opacity 0.2s linear;
	}
	.menu-link .title {
	    -webkit-box-flex: 1;
	    flex-grow: 1;
	    display: -webkit-box;
	    display: flex;
	    flex-shrink: 0;
	    -webkit-box-orient: vertical;
	    -webkit-box-direction: normal;
	    flex-direction: column;
	    line-height: 1;
	    font-size: 14px;
	}	
	.menu-link {
	    height: 56px;
	    padding: 0 12px;
	    font-weight: 400;
	    display: -webkit-box;
	    display: flex;
	    -webkit-box-align: center;
	    align-items: center;
	    -webkit-transition: .2s linear;
	    transition: .2s linear;
	}
	.menu-submenu .menu-link {
    height: 32px;
    font-size: .875rem;
    font-weight: 300;
    letter-spacing: 0;
    color: #fff;
    opacity: 0.8;
    -webkit-transition: 0.2s linear;
    transition: 0.2s linear;
}
	::selection {
	    background: #4ed2c5;
	    color: #fff;
	}
	
.menu-link .arrow::before {

    content: "\e649";
    font-family: themify;
    font-size: 8px;
    cursor: pointer;
    display: inline-block;
    -webkit-transition: -webkit-transform 0.3s linear;
    transition: -webkit-transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;

}
.menu-link > * {

    margin-left: 8px;
    margin-right: 8px;

}
.sidebar .menu-link {

    color: #fff;

}
.sidebar .menu-link {

    position: relative;

}

.sidebar-icons-boxed .menu-submenu .icon, .sidebar-icons-boxed .menu-submenu .dot {
    margin-left: 13px;
    margin-right: 13px;
}
.menu-link .dot {
    position: relative;
    flex-basis: 30px;
    flex-shrink: 0;
    font-size: 10px;
}

