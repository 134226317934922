@font-face {
  font-family: 'superhero';
  src:
    url(/static/media/superhero.3ac0f7c0.ttf) format('truetype'),
    url(/static/media/superhero.34e26ea3.woff) format('woff'),
    url(/static/media/superhero.ce071b15.svg#superhero) format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="superhero-"], [class*=" superhero-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'superhero' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.superhero-batman:before {
  content: "\E900";
}
.superhero-robin:before {
  content: "\E901";
}
.superhero-spiderman:before {
  content: "\E902";
}
.superhero-green-lantern:before {
  content: "\E903";
}
.superhero-hawkeye:before {
  content: "\E904";
}
.superhero-captain-america:before {
  content: "\E905";
}
.superhero-thor:before {
  content: "\E906";
}
.superhero-loki:before {
  content: "\E907";
}
.superhero-green-arrow:before {
  content: "\E908";
}
.superhero-hulk:before {
  content: "\E909";
}
.superhero-superman:before {
  content: "\E90A";
}
.superhero-daredevil:before {
  content: "\E90B";
}
.superhero-wolverine:before {
  content: "\E90C";
}
.superhero-wonder-woman:before {
  content: "\E90D";
}
.superhero-catwoman:before {
  content: "\E90E";
}
.superhero-flash:before {
  content: "\E90F";
}
.superhero-punisher:before {
  content: "\E910";
}
.superhero-ironman:before {
  content: "\E911";
}
.superhero-bpdr:before {
  content: "\E912";
}
.superhero-joker:before {
  content: "\E913";
}
.superhero-two-face-coin:before {
  content: "\E914";
}
.superhero-two-face:before {
  content: "\E915";
}
.superhero-silver-surfer:before {
  content: "\E916";
}
.superhero-magneto:before {
  content: "\E917";
}
.superhero-xavier:before {
  content: "\E918";
}
.superhero-thing:before {
  content: "\E919";
}
.superhero-human-torch:before {
  content: "\E91A";
}
.superhero-mr-fantastic:before {
  content: "\E91B";
}
.superhero-infinity-gauntlet:before {
  content: "\E91C";
}
.superhero-dr-manhattan:before {
  content: "\E91D";
}
.superhero-rorschach:before {
  content: "\E91E";
}
.superhero-venom:before {
  content: "\E91F";
}
.superhero-the-riddler:before {
  content: "\E920";
}
.superhero-aquaman:before {
  content: "\E921";
}
.superhero-spawn:before {
  content: "\E922";
}
.superhero-ghost-rider:before {
  content: "\E923";
}
.superhero-rocketeer:before {
  content: "\E924";
}
.superhero-the-atom:before {
  content: "\E925";
}
.superhero-nick-fury:before {
  content: "\E926";
}
.superhero-cyclops:before {
  content: "\E927";
}
.superhero-judge-dredd:before {
  content: "\E928";
}
.superhero-deadpool:before {
  content: "\E929";
}
.superhero-dr-spectrum:before {
  content: "\E92A";
}
.superhero-solar:before {
  content: "\E92B";
}
.superhero-black-widow:before {
  content: "\E92C";
}
.superhero-iron-fist:before {
  content: "\E92D";
}
.superhero-poison-ivy:before {
  content: "\E92E";
}
.superhero-dr-doom:before {
  content: "\E92F";
}
.superhero-tmnt:before {
  content: "\E930";
}
.superhero-the-spirit:before {
  content: "\E931";
}
.superhero-dr-octopus:before {
  content: "\E932";
}
.superhero-red-skull:before {
  content: "\E933";
}
.superhero-gambit:before {
  content: "\E934";
}
.superhero-scarecrow:before {
  content: "\E935";
}
.superhero-deathstroke:before {
  content: "\E936";
}
.superhero-brainiac:before {
  content: "\E937";
}
.superhero-moon-knight:before {
  content: "\E938";
}
.superhero-karnak:before {
  content: "\E939";
}
.superhero-black-adam:before {
  content: "\E93A";
}
.superhero-penguin:before {
  content: "\E93B";
}
.superhero-shredder:before {
  content: "\E93C";
}
.superhero-hawkeye2:before {
  content: "\E93D";
}
.superhero-superman-hair:before {
  content: "\E93E";
}
.superhero-bane:before {
  content: "\E93F";
}
.superhero-adamantium-shield:before {
  content: "\E940";
}
.superhero-daredevil-glasses:before {
  content: "\E941";
}
.superhero-mutagen:before {
  content: "\E942";
}
.superhero-hellboy:before {
  content: "\E943";
}
.superhero-penguins-umbrella:before {
  content: "\E944";
}
.superhero-starlords-element-gun:before {
  content: "\E945";
}
.superhero-hemans-power-sword:before {
  content: "\E946";
}
.superhero-lukes-1st-lightsaber:before {
  content: "\E947";
}
.superhero-lukes-2nd-lightsaber:before {
  content: "\E948";
}
.superhero-daredevils-billy-cub:before {
  content: "\E949";
}
.superhero-green-goblins-pumpkin:before {
  content: "\E94A";
}
.superhero-kryptonita:before {
  content: "\E94B";
}
.superhero-elektras-sais:before {
  content: "\E94C";
}
.superhero-bow-and-arrow:before {
  content: "\E94D";
}
.superhero-jokers-gun:before {
  content: "\E94E";
}
.superhero-jokers-buzzer:before {
  content: "\E94F";
}
.superhero-leonardos-katana:before {
  content: "\E950";
}
.superhero-michelangelos-nunchakus:before {
  content: "\E951";
}
.superhero-donatellos-bo-staff:before {
  content: "\E952";
}
.superhero-raphaels-sais:before {
  content: "\E953";
}
.superhero-freeze-ray-gun:before {
  content: "\E954";
}
.superhero-new-freeze-ray-gun:before {
  content: "\E955";
}
.superhero-adamantium-claws:before {
  content: "\E956";
}
.superhero-mjolnir:before {
  content: "\E957";
}
.superhero-ghost-riders-hellfire-chain:before {
  content: "\E958";
}
.superhero-dr-stranges-eye-of-agamotto:before {
  content: "\E959";
}
.superhero-gl-power-ring:before {
  content: "\E95A";
}
.superhero-the-phantoms-ring:before {
  content: "\E95B";
}
.superhero-batarang:before {
  content: "\E95C";
}
.superhero-batmans-utility-belt:before {
  content: "\E95D";
}
.superhero-dr-fate:before {
  content: "\E95E";
}
.superhero-x-men-logo:before {
  content: "\E95F";
}
.superhero-fantastic-4:before {
  content: "\E960";
}
.superhero-avengers:before {
  content: "\E961";
}
.superhero-spiderman-spider:before {
  content: "\E962";
}
.superhero-spiderman-web:before {
  content: "\E963";
}

.rst__rowWrapper {
  padding: 10px 10px 10px 0;
  height: 100%;
  box-sizing: border-box;
}

.rst__rtl.rst__rowWrapper {
  padding: 10px 0 10px 10px;
}

.rst__row {
  height: 100%;
  white-space: nowrap;
  display: -webkit-flex;
  display: flex;
}
.rst__row > * {
  box-sizing: border-box;
}

/**
 * The outline of where the element will go if dropped, displayed while dragging
 */
.rst__rowLandingPad,
.rst__rowCancelPad {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
.rst__rowLandingPad > *,
.rst__rowCancelPad > * {
  opacity: 0 !important;
}
.rst__rowLandingPad::before,
.rst__rowCancelPad::before {
  background-color: lightblue;
  border: 3px dashed white;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

/**
 * Alternate appearance of the landing pad when the dragged location is invalid
 */
.rst__rowCancelPad::before {
  background-color: #e6a8ad;
}

/**
 * Nodes matching the search conditions are highlighted
 */
.rst__rowSearchMatch {
  outline: solid 3px #0080ff;
}

/**
 * The node that matches the search conditions and is currently focused
 */
.rst__rowSearchFocus {
  outline: solid 3px #fc6421;
}

.rst__rowContents,
.rst__rowLabel,
.rst__rowToolbar,
.rst__moveHandle,
.rst__toolbarButton {
  display: inline-block;
  vertical-align: middle;
}

.rst__rowContents {
  position: relative;
  height: 100%;
  border: solid #bbb 1px;
  border-left: none;
  box-shadow: 0 2px 2px -2px;
  padding: 0 5px 0 10px;
  border-radius: 2px;
  min-width: 230px;
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background-color: white;
}

.rst__rtl.rst__rowContents {
  border-right: none;
  border-left: solid #bbb 1px;
  padding: 0 10px 0 5px;
}

.rst__rowContentsDragDisabled {
  border-left: solid #bbb 1px;
}

.rst__rtl.rst__rowContentsDragDisabled {
  border-right: solid #bbb 1px;
  border-left: solid #bbb 1px;
}

.rst__rowLabel {
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
  padding-right: 20px;
}
.rst__rtl.rst__rowLabel {
  padding-left: 20px;
  padding-right: inherit;
}

.rst__rowToolbar {
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
  display: -webkit-flex;
  display: flex;
}

.rst__moveHandle,
.rst__loadingHandle {
  height: 100%;
  width: 44px;
  background: #d9d9d9
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+')
    no-repeat center;
  border: solid #aaa 1px;
  box-shadow: 0 2px 2px -2px;
  cursor: move;
  border-radius: 1px;
  z-index: 1;
}

.rst__loadingHandle {
  cursor: default;
  background: #d9d9d9;
}

@-webkit-keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

.rst__loadingCircle {
  width: 80%;
  height: 80%;
  margin: 10%;
  position: relative;
}

.rst__loadingCirclePoint {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.rst__rtl.rst__loadingCirclePoint {
  right: 0;
  left: auto;
  left: initial;
}

.rst__loadingCirclePoint::before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 11%;
  height: 30%;
  background-color: #fff;
  border-radius: 30%;
  -webkit-animation: pointFade 800ms infinite ease-in-out both;
          animation: pointFade 800ms infinite ease-in-out both;
}
.rst__loadingCirclePoint:nth-of-type(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.rst__loadingCirclePoint:nth-of-type(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.rst__loadingCirclePoint:nth-of-type(1)::before,
.rst__loadingCirclePoint:nth-of-type(7)::before {
  -webkit-animation-delay: -800ms;
          animation-delay: -800ms;
}
.rst__loadingCirclePoint:nth-of-type(2) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
}
.rst__loadingCirclePoint:nth-of-type(8) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
}
.rst__loadingCirclePoint:nth-of-type(2)::before,
.rst__loadingCirclePoint:nth-of-type(8)::before {
  -webkit-animation-delay: -666ms;
          animation-delay: -666ms;
}
.rst__loadingCirclePoint:nth-of-type(3) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
}
.rst__loadingCirclePoint:nth-of-type(9) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
}
.rst__loadingCirclePoint:nth-of-type(3)::before,
.rst__loadingCirclePoint:nth-of-type(9)::before {
  -webkit-animation-delay: -533ms;
          animation-delay: -533ms;
}
.rst__loadingCirclePoint:nth-of-type(4) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.rst__loadingCirclePoint:nth-of-type(10) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
.rst__loadingCirclePoint:nth-of-type(4)::before,
.rst__loadingCirclePoint:nth-of-type(10)::before {
  -webkit-animation-delay: -400ms;
          animation-delay: -400ms;
}
.rst__loadingCirclePoint:nth-of-type(5) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
}
.rst__loadingCirclePoint:nth-of-type(11) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
}
.rst__loadingCirclePoint:nth-of-type(5)::before,
.rst__loadingCirclePoint:nth-of-type(11)::before {
  -webkit-animation-delay: -266ms;
          animation-delay: -266ms;
}
.rst__loadingCirclePoint:nth-of-type(6) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
}
.rst__loadingCirclePoint:nth-of-type(12) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
}
.rst__loadingCirclePoint:nth-of-type(6)::before,
.rst__loadingCirclePoint:nth-of-type(12)::before {
  -webkit-animation-delay: -133ms;
          animation-delay: -133ms;
}
.rst__loadingCirclePoint:nth-of-type(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.rst__loadingCirclePoint:nth-of-type(13) {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}
.rst__loadingCirclePoint:nth-of-type(7)::before,
.rst__loadingCirclePoint:nth-of-type(13)::before {
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
}

.rst__rowTitle {
  font-weight: bold;
}

.rst__rowTitleWithSubtitle {
  font-size: 85%;
  display: block;
  height: 0.8rem;
}

.rst__rowSubtitle {
  font-size: 70%;
  line-height: 1;
}

.rst__collapseButton,
.rst__expandButton {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  position: absolute;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #000;
  width: 16px;
  height: 16px;
  padding: 0;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
}
.rst__rtl.rst__collapseButton,
.rst__rtl.rst__expandButton {
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}
.rst__collapseButton:focus,
.rst__expandButton:focus {
  outline: none;
  box-shadow: 0 0 0 1px #000, 0 0 1px 3px #83bef9;
}
.rst__collapseButton:hover:not(:active),
.rst__expandButton:hover:not(:active) {
  background-size: 24px;
  height: 20px;
  width: 20px;
}

.rst__collapseButton {
  background: #fff
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48L2c+Cjwvc3ZnPg==')
    no-repeat center;
}

.rst__expandButton {
  background: #fff
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48cGF0aCBkPSJNOSA0LjV2OSIvPjwvZz4KPC9zdmc+')
    no-repeat center;
}

/**
 * Line for under a node with children
 */
.rst__lineChildren {
  height: 100%;
  display: inline-block;
  position: absolute;
}
.rst__lineChildren::after {
  content: '';
  position: absolute;
  background-color: black;
  width: 1px;
  left: 50%;
  bottom: 0;
  height: 10px;
}

.rst__rtl.rst__lineChildren::after {
  right: 50%;
  left: auto;
  left: initial;
}

.rst__rowWrapper {
  padding: 10px 10px 10px 0;
  height: 100%;
  box-sizing: border-box;
}

.rst__rtl.rst__rowWrapper {
  padding: 10px 0 10px 10px;
}

.rst__row {
  height: 100%;
  white-space: nowrap;
  display: -webkit-flex;
  display: flex;
}
.rst__row > * {
  box-sizing: border-box;
}

/**
 * The outline of where the element will go if dropped, displayed while dragging
 */
.rst__rowLandingPad,
.rst__rowCancelPad {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
.rst__rowLandingPad > *,
.rst__rowCancelPad > * {
  opacity: 0 !important;
}
.rst__rowLandingPad::before,
.rst__rowCancelPad::before {
  background-color: lightblue;
  border: 3px dashed white;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

/**
 * Alternate appearance of the landing pad when the dragged location is invalid
 */
.rst__rowCancelPad::before {
  background-color: #e6a8ad;
}

/**
 * Nodes matching the search conditions are highlighted
 */
.rst__rowSearchMatch {
  outline: solid 3px #0080ff;
}

/**
 * The node that matches the search conditions and is currently focused
 */
.rst__rowSearchFocus {
  outline: solid 3px #fc6421;
}

.rst__rowContents,
.rst__rowLabel,
.rst__rowToolbar,
.rst__moveHandle,
.rst__toolbarButton {
  display: inline-block;
  vertical-align: middle;
}

.rst__rowContents {
  position: relative;
  height: 100%;
  border: solid #bbb 1px;
  border-left: none;
  box-shadow: 0 2px 2px -2px;
  padding: 0 5px 0 10px;
  border-radius: 2px;
  min-width: 230px;
  -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  background-color: white;
}

.rst__rtl.rst__rowContents {
  border-right: none;
  border-left: solid #bbb 1px;
  padding: 0 10px 0 5px;
}

.rst__rowContentsDragDisabled {
  border-left: solid #bbb 1px;
}

.rst__rtl.rst__rowContentsDragDisabled {
  border-right: solid #bbb 1px;
  border-left: solid #bbb 1px;
}

.rst__rowLabel {
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
  padding-right: 20px;
}
.rst__rtl.rst__rowLabel {
  padding-left: 20px;
  padding-right: inherit;
}

.rst__rowToolbar {
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
  display: -webkit-flex;
  display: flex;
}

.rst__moveHandle,
.rst__loadingHandle {
  height: 100%;
  width: 44px;
  background: #d9d9d9
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+')
    no-repeat center;
  border: solid #aaa 1px;
  box-shadow: 0 2px 2px -2px;
  cursor: move;
  border-radius: 1px;
  z-index: 1;
}

.rst__loadingHandle {
  cursor: default;
  background: #d9d9d9;
}

@-webkit-keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

.rst__loadingCircle {
  width: 80%;
  height: 80%;
  margin: 10%;
  position: relative;
}

.rst__loadingCirclePoint {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.rst__rtl.rst__loadingCirclePoint {
  right: 0;
  left: auto;
  left: initial;
}

.rst__loadingCirclePoint::before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 11%;
  height: 30%;
  background-color: #fff;
  border-radius: 30%;
  -webkit-animation: pointFade 800ms infinite ease-in-out both;
          animation: pointFade 800ms infinite ease-in-out both;
}
.rst__loadingCirclePoint:nth-of-type(1) {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
}
.rst__loadingCirclePoint:nth-of-type(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.rst__loadingCirclePoint:nth-of-type(1)::before,
.rst__loadingCirclePoint:nth-of-type(7)::before {
  -webkit-animation-delay: -800ms;
          animation-delay: -800ms;
}
.rst__loadingCirclePoint:nth-of-type(2) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
}
.rst__loadingCirclePoint:nth-of-type(8) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
}
.rst__loadingCirclePoint:nth-of-type(2)::before,
.rst__loadingCirclePoint:nth-of-type(8)::before {
  -webkit-animation-delay: -666ms;
          animation-delay: -666ms;
}
.rst__loadingCirclePoint:nth-of-type(3) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
}
.rst__loadingCirclePoint:nth-of-type(9) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
}
.rst__loadingCirclePoint:nth-of-type(3)::before,
.rst__loadingCirclePoint:nth-of-type(9)::before {
  -webkit-animation-delay: -533ms;
          animation-delay: -533ms;
}
.rst__loadingCirclePoint:nth-of-type(4) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.rst__loadingCirclePoint:nth-of-type(10) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
.rst__loadingCirclePoint:nth-of-type(4)::before,
.rst__loadingCirclePoint:nth-of-type(10)::before {
  -webkit-animation-delay: -400ms;
          animation-delay: -400ms;
}
.rst__loadingCirclePoint:nth-of-type(5) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
}
.rst__loadingCirclePoint:nth-of-type(11) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
}
.rst__loadingCirclePoint:nth-of-type(5)::before,
.rst__loadingCirclePoint:nth-of-type(11)::before {
  -webkit-animation-delay: -266ms;
          animation-delay: -266ms;
}
.rst__loadingCirclePoint:nth-of-type(6) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
}
.rst__loadingCirclePoint:nth-of-type(12) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
}
.rst__loadingCirclePoint:nth-of-type(6)::before,
.rst__loadingCirclePoint:nth-of-type(12)::before {
  -webkit-animation-delay: -133ms;
          animation-delay: -133ms;
}
.rst__loadingCirclePoint:nth-of-type(7) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.rst__loadingCirclePoint:nth-of-type(13) {
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}
.rst__loadingCirclePoint:nth-of-type(7)::before,
.rst__loadingCirclePoint:nth-of-type(13)::before {
  -webkit-animation-delay: 0ms;
          animation-delay: 0ms;
}

.rst__rowTitle {
  font-weight: bold;
}

.rst__rowTitleWithSubtitle {
  font-size: 85%;
  display: block;
  height: 0.8rem;
}

.rst__rowSubtitle {
  font-size: 70%;
  line-height: 1;
}

.rst__collapseButton,
.rst__expandButton {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: none;
  position: absolute;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #000;
  width: 16px;
  height: 16px;
  padding: 0;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  cursor: pointer;
}
.rst__rtl.rst__collapseButton,
.rst__rtl.rst__expandButton {
  -webkit-transform: translate(50%, -50%);
          transform: translate(50%, -50%);
}
.rst__collapseButton:focus,
.rst__expandButton:focus {
  outline: none;
  box-shadow: 0 0 0 1px #000, 0 0 1px 3px #83bef9;
}
.rst__collapseButton:hover:not(:active),
.rst__expandButton:hover:not(:active) {
  background-size: 24px;
  height: 20px;
  width: 20px;
}

.rst__collapseButton {
  background: #fff
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48L2c+Cjwvc3ZnPg==')
    no-repeat center;
}

.rst__expandButton {
  background: #fff
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48cGF0aCBkPSJNOSA0LjV2OSIvPjwvZz4KPC9zdmc+')
    no-repeat center;
}

/**
 * Line for under a node with children
 */
.rst__lineChildren {
  height: 100%;
  display: inline-block;
  position: absolute;
}
.rst__lineChildren::after {
  content: '';
  position: absolute;
  background-color: black;
  width: 1px;
  left: 50%;
  bottom: 0;
  height: 10px;
}

.rst__rtl.rst__lineChildren::after {
  right: 50%;
  left: auto;
  left: initial;
}

.conversation-list {
    list-style: none;
    padding: 0 15px;
}

.conversation-list  dl, ol, ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

.conversation-list li {
    margin-bottom: 20px;
}

.conversation-list .chat-avatar {
    float: left;
    text-align: center;
    width: 72px;
}

.conversation-list .odd .chat-avatar {
    float: right!important;
}

.conversation-list .odd .conversation-text {
    float: right!important;
    margin-right: 12px;
    text-align: right;
    width: 70%!important;
}
.conversation-list .odd .ctext-wrap {
    background-color: #FEF5E4;
}

.conversation-list .chat-avatar i {
    font-size: 12px;
    font-style: normal;
}

.conversation-list .conversation-text {
    float: left;
    font-size: 12px;
    margin-left: 12px;
    width: 70%;
}

.conversation-list .odd .ctext-wrap:after {
    border-color: transparent;
    border-left-color: #FEF5E4;
    border-top-color: #FEF5E4;
    right: -10px!important;
    left: auto;
}

.conversation-list .ctext-wrap {
    background: #F1F3FA;
    border-radius: 3px;
    display: inline-block;
    padding: 12px;
    position: relative;
}

.conversation-list .ctext-wrap:after {
    left: -10px;
    top: 0;
    border-width: medium;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-left: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-top: 6px solid transparent;
    border-top-color: #F1F3FA;
    margin-right: -1px;
    border-right: 6px solid transparent;
    border-right-color: #F1F3FA;
}

.ReactModal__Overlay--after-open {
	z-index: 4000 !important;
}

div.note {
    height: 180px;
    width: 200px;
    background-color: #feff9c;
    margin: 2px 0;
    position: absolute;
    cursor: -webkit-grab;
    box-shadow: 5px 5px 15px 0 rgb(0 0 0 / 20%);
    border-bottom-right-radius:60px 5px;
    z-index: 10000;
}

div.note::after {
  content:"";
  position: absolute;
  bottom: 0;
  right: -4px;
  width: 100%;
  height: 140px;
  background-image: -webkit-linear-gradient(277deg, rgba(0,0,0,0) 92%,
  rgba(0,0,0,0.4) 100%);
  background-image: linear-gradient(173deg, rgba(0,0,0,0) 92%,
  rgba(0,0,0,0.4) 100%);
  -webkit-transform: rotate(6deg);
          transform: rotate(6deg);
  z-index: -1;
  -webkit-filter: blur(2px);
          filter: blur(2px);
}

#xwrapper {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	height: 100%;
	min-height: 100%;
}

.dialogTitle {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}

.thumb img {
	object-fit: cover;
	width:100%;
	height:100%
}
.thumb {
	padding: 0 !important;
}

.grid-Cell-Left {
	background-color: #fff !important;
}
.grid-Cell-Left:hover {
	background-color: #fff !important;
}
.rst__row > .rst__rowContents > .rst__rowLabel > .rst__rowTitle > .selectedNode {
	border: 1px solid red;
}

.selectedNode {
	border: 2px solid red;
}

.defaultSearch > label {
	top: -10px !important;	
}
.defaultSearch > label + .MuiInput-formControl {
	margin-top: 0px;
}

div[role="alertdialog"] {
	background-color: #fddc6c !important;
    max-width: 400px;
    min-width: 344px;
}

div[class^="SnackbarItem-base-"], div[class*=" SnackbarItem-base-"] {
	background-color: #fddc6c ;
    max-width: 400px;
    min-width: 344px;
}
.MuiSnackbarContent-root {
	-webkit-align-items: start;
	        align-items: start;
}

div.MuiSnackbarContent-action {
}

.MuiSnackbarContent-action {
	-webkit-align-items: start;
	        align-items: start;
}


div[class^="SnackbarItem-variantInfo-"], div[class*=" SnackbarItem-variantInfo-"] {
	background-color: #008bd4 !important;
    max-width: 400px;
    min-width: 344px;
}
.snackDownloadBottom {
	background-color: #fff;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
}


.loader {
  width: 250px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  font-family: helvetica, arial, sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  color: rgb(229, 57, 53);
  letter-spacing: 0.2em;
}

.loader::before, .loader::after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background: rgb(229, 57, 53);
    position: absolute;
    -webkit-animation: load .9s infinite alternate ease-in-out;
            animation: load .9s infinite alternate ease-in-out;
  }
  
 .loader::before {
    top: 0;
  }
  
.loader::after {
    bottom: 0;
  }


@-webkit-keyframes load {
  0% { left: 0; height: 30px; width: 15px }
  50% { height: 8px; width: 40px }
  100% { left: 235px; height: 30px; width: 15px}
}


@keyframes load {
  0% { left: 0; height: 30px; width: 15px }
  50% { height: 8px; width: 40px }
  100% { left: 235px; height: 30px; width: 15px}
}

.react-pdf__Page {
	background-color: black;
	background-image: url(/images/raster.png);
}
.react-pdf__Page__canvas {
	margin: 0 auto;
}
.rst__rowWrapper {
	padding: 5px 5px 5px 0;
}
.rst__moveHandle
 {
	background-image : none;
	padding-top : 3px;
	text-align: center;
}
.icon-a > .rst__moveHandle::before {
	content : "\F072";
	color : black;
	font : normal normal normal 16px FontAwesome;
}
.icon-b > .rst__moveHandle::before {
	content : "\F015";
	color : black;
	font : normal normal normal 16px FontAwesome;
}
.query > .rst__moveHandle::before {
	content : "\F0CE";
	color : black;
	font : normal normal normal 16px FontAwesome;
}
.bookmarkfolder > .rst__moveHandle::before {
	content : "\F115";
	color : black;
	font : normal normal normal 16px FontAwesome;
}
.bookmark > .rst__moveHandle::before {
	content : "\F097";
	color : black;
	font : normal normal normal 16px FontAwesome;
}

.sub > .rst__moveHandle::before {
	content : "\F0E8";
	color : black;
	font : normal normal normal 16px FontAwesome;
}
.item > .rst__moveHandle::before {
	content : "\F1C0";
	color : black;
	font : normal normal normal 16px FontAwesome;
}
.MaskItemAddDialog {
	z-index: 2000 !important;
}

div[id^='menu-modi_']
{
		z-index: 1550 !important;
}
.modal {
	z-index: 1500 !important;
}
.modal-backdrop {
	z-index: 1490 !important;
}
.menu-item > .MuiPaper-root {
	background-color: transparent;
	color: white;
	box-shadow: none;
}
.menu-item > .MuiPaper-root:hover {
	background-color: #303030;	
}

.marginTop {
	margin-top: 20px;
}

.planingButtonSelect {
	background-color: transparent !important;
	border-color: transparent !important;
	color: black !important;
}

.planingButtonSelect:hover {
	background-color: rgba(0,0,0, 0.08) !important;
	border-color: rgba(0,0,0, 0.08) !important;
	color: black !important;
}

.planingSelect {
	margin-top: 26px !important;
}

.navMuiButton-root:hover {
	background-color: #fafafa !important;
}


.rowMaxWidth {
	width:100%
}

.planingCard {
	min-height: calc(100% - 80px);
	margin-left: 3px;
}

.planingCard > div > .MuiCollapse-entered, .planingCard > div > div > .MuiCollapse-wrapper {
	background-color: white;
}

.planningElement > div > .MuiIconButton-label {
	color: black;
}

.MuiIconButton-label {
	color: white;
}

.MuiPickersBasePicker-container .MuiIconButton-label {
	color: black;
}

.MuiExpansionPanelDetails-root {
	background-color: transparent;
}
.MuiExpansionPanelDetails-root {	
	margin: 20px;
	margin-top: 0px;
}


.MuiCollapse-container {
	background-color: transparent;
}

.MuiPaper-root {
	background-color: rgb(69, 81, 96);
}
.applicationIcon {
    font-size: 24px !important;
    padding: 5px;
}
.startPageTop {
    height: 180px;

}
.applicationTopbar {
    height: 70px;
}
.profilHeight {
	height: 250px;
}
.disablePaddingRight {
    padding-right: 0px !important;
}

.disablePaddingLeft {
    padding-left: 0px !important;
}

.notificationFrame {
    min-width: 300px;
}

.applicationSidebar {
    background: #455160;
	height: calc(100% - 0px);
    background: #000;
    opacity: .85;
}
.applicationSidebar {
}

.applicationSidebarImg {
	background-image: url("/images/sidebar-2.jpg");
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-position: 50%;	
}
.applicationActionTop, .applicationSidebarTop, .applicationSidebarBottom {
    height: 40px;
    line-height: 40px;
    padding-left: 10px;
    padding-top: 5px;
}

.applicationActionTop, .applicationSidebarTop, .applicationSidebarBottom {
    background: black;
    color: rgb(228, 231, 234);
}

.applicationTopbar {
    padding-top: 10px;
    padding-left: 10px;
}

.applicationTopbarItem {
    min-width: 50px;
}

.applicationActionTop {
    background: white;
    color: #2f353a;
}

.applicationAction {
    height: calc(100% - 40px);
    background: #e0e0e0;
    color: #2f353a;
    padding: 10px;
}

.applicationSidebarNav {
    height: calc(100% - 80px);
    color: white;
}

.textColorLight {
    color: white;
}
.notificationHeader {
    padding: 20px;
}
.searchRoot {
    padding: 2px 4px;
    display: -webkit-flex;
    display: flex;
    alignItems: center;
    width: 400;
}
.searchInput {
    marginLeft: 8px;
    -webkit-flex: 1 1;
            flex: 1 1;
}
.searchIconButton {
    padding: 10px;
}
.searchDivider {
    width: 1px;
    height: 28px;
    margin: 4px;
}

.menu {
	    list-style: none;
	    padding-left: 0;
	    margin-bottom: 30px;
		font-family: Roboto,sans-serif;
		color: #4d5259;
		font-weight: 300;
		font-size: 13px;
		line-height: 24px;
	}
	
	.menu-submenu {
	    background-color: transparent;
	    padding-top: 1rem;
	    padding-bottom: 1rem;
	    padding-left: 0;
	    display: block;
	    list-style-type: none;
		margin-bottom: 0;
		width: 100%;
	}

	.MuiExpansionPanelDetails-root {
		padding: 0px !important;
	}
	
	.icon-left {
	    -webkit-order: 1;
	            order: 1;
	    font-size: 16px;
        background-color: rgba(0,0,0,0.07);
        -webkit-flex-basis: 40px;
                flex-basis: 40px;
        line-height: 40px;
        border-radius: 2px;
        font-weight: 500;
        letter-spacing: 1px;
        text-align: center;
	}	
	
	ul.menu  .menu-item a {
		text-decoration:  none;
	}
		
	ul.menu  .menu-item a:hover {
		background: #808080;
	}
	
	.menu-category {
	    position: relative;
	    font-family: Roboto,sans-serif;
	    display: block;
	    text-transform: uppercase;
	    letter-spacing: 1px;
	    font-size: 0.6875rem;
	    font-weight: 500;
	    line-height: 32px;
	    padding: 1rem 20px 0.5rem;
	    color: #fff;
	    opacity: 0.7;
	    white-space: nowrap;
	    overflow: hidden;
	    text-overflow: ellipsis;
	 }
	.menu-category::after {
	    content: '';
	    width: 100%;
	    height: 0;
	    border-top: 1px dashed #fff;
	    vertical-align: middle;
	    margin-left: 1.5rem;
	    opacity: 0.3;
	    position: absolute;
	    top: 50%;
	    margin-top: 3px;
	}	 
	.menu-item {
	    vertical-align: top;
	    -webkit-transition: opacity 0.2s linear;
	    transition: opacity 0.2s linear;
	}
	.menu-link .title {
	    -webkit-flex-grow: 1;
	            flex-grow: 1;
	    display: -webkit-flex;
	    display: flex;
	    -webkit-flex-shrink: 0;
	            flex-shrink: 0;
	    -webkit-flex-direction: column;
	            flex-direction: column;
	    line-height: 1;
	    font-size: 14px;
	}	
	.menu-link {
	    height: 56px;
	    padding: 0 12px;
	    font-weight: 400;
	    display: -webkit-flex;
	    display: flex;
	    -webkit-align-items: center;
	            align-items: center;
	    -webkit-transition: .2s linear;
	    transition: .2s linear;
	}
	.menu-submenu .menu-link {
    height: 32px;
    font-size: .875rem;
    font-weight: 300;
    letter-spacing: 0;
    color: #fff;
    opacity: 0.8;
    -webkit-transition: 0.2s linear;
    transition: 0.2s linear;
}
	::-moz-selection {
	    background: #4ed2c5;
	    color: #fff;
	}
	::selection {
	    background: #4ed2c5;
	    color: #fff;
	}
	
.menu-link .arrow::before {

    content: "\E649";
    font-family: themify;
    font-size: 8px;
    cursor: pointer;
    display: inline-block;
    -webkit-transition: -webkit-transform 0.3s linear;
    transition: -webkit-transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;

}
.menu-link > * {

    margin-left: 8px;
    margin-right: 8px;

}
.sidebar .menu-link {

    color: #fff;

}
.sidebar .menu-link {

    position: relative;

}

.sidebar-icons-boxed .menu-submenu .icon, .sidebar-icons-boxed .menu-submenu .dot {
    margin-left: 13px;
    margin-right: 13px;
}
.menu-link .dot {
    position: relative;
    -webkit-flex-basis: 30px;
            flex-basis: 30px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    font-size: 10px;
}


.cellSticky {
	position: -webkit-sticky;
	position: sticky;
    left: 0;
    background: white;
    boxShadow: 5px 2px 5px grey;
    borderRight: 2px solid black	
}

     .xresizable {
     position: relative;
    }
    .xreact-resizable {
      position: absolute;
      width: 20px;
      height: 20px;
      bottom: 0;
      right: 0;
      background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
      background-position: bottom right;
      padding: 0 3px 3px 0;
      background-repeat: no-repeat;
      background-origin: content-box;
      box-sizing: border-box;
      cursor: se-resize;
    }

.group--actions, .group--actions--tr {
	opacity: 100 !important;
}

.redComment {
	color: #dc3545;
}

.fa_alpha_asc:after {
   font-family: FontAwesome;
   content: "\F15D";
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);   
}

.fa_alpha_desc:after {
   font-family: FontAwesome;
   content: "\F15E";
    position: absolute;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);   
}

.MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 12px 0 !important;
}

.MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 12px 0 !important;
}

expanded {
    margin: 12px 0 !important;
}

.naviGridButton {
    color: white !important;
}
.naviGridButton:hover {
    color: black !important;
}

.headlineDashboard {
    margin: 10px;
    margin-top: -30px;
    color: rgba(0, 0, 0, 0.54);
}

.innerCenter {
  display: table;
  margin: 0 auto;
  line-height: 56px;
  color: white;
  font-size: 2em;
}

.card-icon{
    width: 60px;
    height: 60px;
    position: relative;
    top: -15px;
    left: 20px;
    box-shadow: 0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(255,152,0,.4);
    background: -webkit-linear-gradient(30deg,#ffa726,#fb8c00);
    background: linear-gradient(60deg,#ffa726,#fb8c00);
}

.drawerDragger {
    width: 5px;
    cursor: ew-resize;
    padding: 4px 0 0;
    border-top: "1px solid #ddd";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #e0e0e0;
}

.iconBig {
    font-size: 1.5rem !important;
}

.readOnlyIcon > label::after {
    content: "\A0   \F05A";
    font-family: "FontAwesome";
}

.readOnlyAdvice {
    float:right;
    font-size: 12px;
}

div[id*='menu-']  {
    z-index: 4000 !important;
}

.zInd {
    z-index: 3000 !important;
}

.previewSpread {
    padding-bottom: 12px;
}

.highlightGridCell {
    background-color: #d23619 !important;
}

.planningPreviewPaper {
    margin-bottom: 20px;
}
.planningPreviewPaperTop {
    margin-top: 20px !important;

}

.planningPreviewPage {
    border: 1px solid #aaa;
    background-color: #fff;
    width: 100px;
    height: 200px;
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
}

.paddingBottom {
    margin-bottom: 15px;
}


.planningPreviewPageHidden {
    border: 0px solid white;
    background-color: #fff;
    width: 100px;
    height: 200px;
}

.password + .unmask {
  position: absolute;
  right: 12px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor:pointer;
  border: none;
    z-index:1000;
  -webkit-appearance:none;
}

.password + .unmask:before {

  position: absolute;
  top: 4px;
  left: 4px;
  width: 17px;
  height: 17px;
  background: #e3e3e3;
  z-index:1000;
  border-radius: 50%;
}

.password[type="text"] + .unmask:after {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
}

div > .MuiIconButton-label > .MuiSvgIcon-root {
    fill: white !important;
}

.MuiSvgIcon-root  {
    fill: black !important;
}

.dialogHeight {
    height: 500px;
}
.importPreviewRows {
    max-height: 250px;
    overflow: scroll;
    
}

.selectSmall {
    padding: 4px !important;
    font-size: 1.0em !important;
}

.selectSmall > .MuiIconButton-label {
    font-size: 1.0em !important;
}

.selectSmall > .MuiIconButton-label > .MuiSvgIcon-root {
    font-size: 1.0em !important;
}

.fontColorWhite {
    color: white !important;
}

.maskfieldsAreaTop {
	display: inline-block;
	width: 100%;
	border-left: 1px solid rgba(0, 0, 0, 0.2);
}
.inlineFlex {
	display: -webkit-flex;
	display: flex;
}
.addMaskFields {
	width: 400px;
}
.addMaskFields > div > .MuiIconButton-label, .checkBoxDark > .MuiIconButton-label{
	color: black;
}

.addMaskFields > .MuiCollapse-container {
	background-color: white !important;	
}

.maskfieldsArea {
    min-width: 2200px !important;
    max-height: 300px;
}

.maxWidthPlaning {
	width: 100%;
}

.maxWidth {
	width: 100%;
	height: 120px;
	border: 1px solid black;
}

.height300 {
	height: 300px;
}

.height200 {
	height: 200px;
}

.marginQueryInput {
	margin-top: 0px;
	margin-left: 5px;
}

.loginHeader {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.loginHeader > span > .MuiAvatar-colorDefault {
	background-color: red;
}
.avProfilTop {
	margin-top: 20px !important;
}
.tdHeight{
	max-height: 32px;
	overflow: hidden;
} 

.lineHeight60 {
	line-height: 60px;
}

.profilAvatar {
    position: relative;
    display: inline-block;
    width: 72px;
    height: 72px;
    line-height: 72px;
    text-align: center;
    border-radius: 100%;
    background-color: #f5f6f7;
    color: #8b95a5;
    text-transform: uppercase;
	margin-top: 40px;
	margin-left: 20px;
}

.profilAvatarSmall {
	font-size: 16px;
	position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 100%;
    background-color: #f5f6f7;
    color: #8b95a5;
    text-transform: uppercase;
	margin-top: 0px;
	margin-left: 0px;
}
.profilAvatarVerySmall {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 100%;
    background-color: #f5f6f7;
    color: #8b95a5;
    text-transform: uppercase;
	margin-top: 0px;
	margin-left: 0px;
	margin-right: 5px;
}
.profilAvatarVerySmallTXT {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 100%;
    background-color: #f5f6f7;
    color: #8b95a5;
    text-transform: uppercase;
	margin-top: 0px;
	margin-left: 0px;
	margin-right: 5px;
}
.icon-stack {
      position: relative;
      display: inline-block;
      width: 2em;
      vertical-align: middle;
    }
    .icon-stack-1x,
    .icon-stack-2x,
    .icon-stack-3x {
      position: absolute;
      left: 0;
      width: 100%;
      text-align: right;
    }
    .icon-stack-1x {
      line-height: inherit;
      left: 5px;
      top: 3px;
      font-size: 8px;
      color: #a0a0a0;
    }
    .icon-stack-2x {
      font-size: 1.5em;
    }
    .icon-stack-3x {
    }

.avatar {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border-radius: 100%;
    background-color: #f5f6f7;
    color: #8b95a5;
    text-transform: uppercase;
}

.fileUploader {
	margin-top: 20px;
}

.planingPreview {
	background-image: url("/images/raster.png");
}

.planingPreviewPage {
    margin-left: 5px;
    margin-top: 5px; 
    float: left;
    border: 2px solid #999;
    box-shadow: 9px 9px 12px -7px rgba(153,153,153,1);    
}

.previewBorder {
	max-width:340px;
	max-height:220px;
	min-width:340px;
	min-height:220px;
	background-image: url("/images/raster.png");
	text-align: center;
	vertical-align: bottom;
	margin-top: 20px;
	margin-bottom: 20px;
}

.previewBorderSmall {
	max-width:60px;
	max-height:60px;
	min-width:60px;

	text-align: center;
	vertical-align: bottom;
}

.fileContainer {
	box-shadow: none;
}


.previewSmall {
	
	max-width:50px;
	max-height:50px;
	width: auto;
	height: auto;

}
.previewBig {
	
	max-width:320px;
	max-height:200px;
	width: auto;
	height: auto;

}

.buttonIcon {
    outline: 0px !important;
    min-width: 24px !important;
    border-radius: 20px !important;
}

.smallPadding {
    padding-left: 10px;
}

.modalBody {
    min-height: 400px;
    max-height: 400px;
    overflow: scroll;
}

.fa-actice {
    color: rgb(239, 83, 80);
}

.showLink {
    cursor: pointer;
}

.vivaGridBody {
    min-height: 525px;
}

.gridBottom {
    background: #aaa;
    color: rgb(228, 231, 234);
    line-height: 42px;
    padding: 3px;
}

.grid-Row:hover .grid-Cell {
    background: #f5f6f7 !important;
}

.maskField_50 {
    min-width: 50px !important;
    max-width: 50px !important;
}
.maskField_100 {
    min-width: 100px !important;
    max-width: 100px !important;
}
.maskField_150 {
    min-width: 150px !important;
    max-width: 150px !important;
}

.maskField_200 {
    min-width: 200px !important;
    max-width: 200px !important;
}
.maskField_250 {
    min-width: 250px !important;
    max-width: 250px !important;
}

.maskField_300 {
    min-width: 300px !important;
    max-width: 300px !important;
}

.maskField_400 {
    min-width: 400px !important;
    max-width: 400px !important;
}

.maskField_500 {
    min-width: 500px !important;
    max-width: 500px !important;
}

.maskFieldRow {

}

.MuiTypography-body1 {
	font-weight: bold !important;
}

.grid-Cell {
    background-color: #fff;
    padding-left: 8px;
    padding-right: 8px;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #ddd;
    width:240px;
    min-height:35px;
    display: inline-grid;
}

.grid-Cell:not(.editing):not(.rdg-child-cell) .grid-Cell__value, .grid-Cell:not(.editing):not(.rdg-child-cell) .grid-Cell__header {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}


.grid-Cell:not(.editing) .grid-Cell__value, .grid-Cell:not(.editing) .grid-Cell__header {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.grid-Cell-Header { 
    background: #aaa;
    color: #eee;
}

.grid-Cell__value ul {
    margin-top: 5px;
}



.grid-Cell__value a, .grid-Cell__value a:hover {
    color: #000;
}

.alignItems{
	-webkit-align-items: flex-start !important;
	        align-items: flex-start !important;
}

.rowpadding {
	padding: 10px;
}

.Vivacard {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #c8ced3;
    border-radius: .25rem;
}

.Vivacardheader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: #f0f3f5;
    border-bottom: 1px solid #c8ced3;
}

.Vivacardbody {
    -webkit-flex: 1 1 auto;
            flex: 1 1 auto;
    overflow-x: scroll;
    height: auto;
}

.BreadCrumb {
    background-color: #1976d2 !important;
    height: 24px !important;
    margin-left: 6px !important;
}

.border {
    border: 1px solid red;
   
}

.gridIcon {
    line-height: 30px;
    min-width: 20px;
}

.bg-img {
    background-image: url(/images/maxresdefault.jpg);
    position: relative;
    border-bottom: none;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
}

.min-h-fullscreen {
    min-height: 100vh !important;
}

.py-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.px-80 {
    padding-right: 80px !important;
    padding-left: 80px !important;
}

.input-group-text {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5c6873;
    text-align: center;
    white-space: nowrap;
    background-color: #f0f3f5;
    border: 1px solid #e4e7ea;
    border-radius: .25rem;
}

.displayThis {
	display: block;
}

.hideThis {
	display: none;
}

.clearmargin {
    margin: 0px !important;
}

.maxWidth {
	-webkit-flex: 1 1;
	        flex: 1 1;
	padding: 10px;
}

.appBar {
	position: relative !important;
	max-height: 64px;
  }

.title {
    -webkit-flex: 1 1;
            flex: 1 1;
  }

button {
    outline: 0px !important;
}
