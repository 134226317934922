.cellSticky {
	position: sticky;
    left: 0;
    background: white;
    boxShadow: 5px 2px 5px grey;
    borderRight: 2px solid black	
}

     .xresizable {
     position: relative;
    }
    .xreact-resizable {
      position: absolute;
      width: 20px;
      height: 20px;
      bottom: 0;
      right: 0;
      background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
      background-position: bottom right;
      padding: 0 3px 3px 0;
      background-repeat: no-repeat;
      background-origin: content-box;
      box-sizing: border-box;
      cursor: se-resize;
    }

.group--actions, .group--actions--tr {
	opacity: 100 !important;
}

.redComment {
	color: #dc3545;
}

.fa_alpha_asc:after {
   font-family: FontAwesome;
   content: "\f15d";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);   
}

.fa_alpha_desc:after {
   font-family: FontAwesome;
   content: "\f15e";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);   
}

.MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 12px 0 !important;
}

.MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 12px 0 !important;
}

expanded {
    margin: 12px 0 !important;
}

.naviGridButton {
    color: white !important;
}
.naviGridButton:hover {
    color: black !important;
}

.headlineDashboard {
    margin: 10px;
    margin-top: -30px;
    color: rgba(0, 0, 0, 0.54);
}

.innerCenter {
  display: table;
  margin: 0 auto;
  line-height: 56px;
  color: white;
  font-size: 2em;
}

.card-icon{
    width: 60px;
    height: 60px;
    position: relative;
    top: -15px;
    left: 20px;
    box-shadow: 0 4px 20px 0 rgba(0,0,0,.14), 0 7px 10px -5px rgba(255,152,0,.4);
    background: linear-gradient(60deg,#ffa726,#fb8c00);
}

.drawerDragger {
    width: 5px;
    cursor: ew-resize;
    padding: 4px 0 0;
    border-top: "1px solid #ddd";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #e0e0e0;
}

.iconBig {
    font-size: 1.5rem !important;
}

.readOnlyIcon > label::after {
    content: "\00a0 \f05a";
    font-family: "FontAwesome";
}

.readOnlyAdvice {
    float:right;
    font-size: 12px;
}

div[id*='menu-']  {
    z-index: 4000 !important;
}

.zInd {
    z-index: 3000 !important;
}

.previewSpread {
    padding-bottom: 12px;
}

.highlightGridCell {
    background-color: #d23619 !important;
}

.planningPreviewPaper {
    margin-bottom: 20px;
}
.planningPreviewPaperTop {
    margin-top: 20px !important;

}

.planningPreviewPage {
    border: 1px solid #aaa;
    background-color: #fff;
    width: 100px;
    height: 200px;
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
}

.paddingBottom {
    margin-bottom: 15px;
}


.planningPreviewPageHidden {
    border: 0px solid white;
    background-color: #fff;
    width: 100px;
    height: 200px;
}

.password + .unmask {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor:pointer;
  border: none;
    z-index:1000;
  -webkit-appearance:none;
}

.password + .unmask:before {

  position: absolute;
  top: 4px;
  left: 4px;
  width: 17px;
  height: 17px;
  background: #e3e3e3;
  z-index:1000;
  border-radius: 50%;
}

.password[type="text"] + .unmask:after {
  content: "";
  position: absolute;
  top: 6px;
  left: 6px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
}

div > .MuiIconButton-label > .MuiSvgIcon-root {
    fill: white !important;
}

.MuiSvgIcon-root  {
    fill: black !important;
}

.dialogHeight {
    height: 500px;
}
.importPreviewRows {
    max-height: 250px;
    overflow: scroll;
    
}

.selectSmall {
    padding: 4px !important;
    font-size: 1.0em !important;
}

.selectSmall > .MuiIconButton-label {
    font-size: 1.0em !important;
}

.selectSmall > .MuiIconButton-label > .MuiSvgIcon-root {
    font-size: 1.0em !important;
}

.fontColorWhite {
    color: white !important;
}

.maskfieldsAreaTop {
	display: inline-block;
	width: 100%;
	border-left: 1px solid rgba(0, 0, 0, 0.2);
}
.inlineFlex {
	display: flex;
}
.addMaskFields {
	width: 400px;
}
.addMaskFields > div > .MuiIconButton-label, .checkBoxDark > .MuiIconButton-label{
	color: black;
}

.addMaskFields > .MuiCollapse-container {
	background-color: white !important;	
}

.maskfieldsArea {
    min-width: 2200px !important;
    max-height: 300px;
}

.maxWidthPlaning {
	width: 100%;
}

.maxWidth {
	width: 100%;
	height: 120px;
	border: 1px solid black;
}

.height300 {
	height: 300px;
}

.height200 {
	height: 200px;
}

.marginQueryInput {
	margin-top: 0px;
	margin-left: 5px;
}

.loginHeader {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.loginHeader > span > .MuiAvatar-colorDefault {
	background-color: red;
}
.avProfilTop {
	margin-top: 20px !important;
}
.tdHeight{
	max-height: 32px;
	overflow: hidden;
} 

.lineHeight60 {
	line-height: 60px;
}

.profilAvatar {
    position: relative;
    display: inline-block;
    width: 72px;
    height: 72px;
    line-height: 72px;
    text-align: center;
    border-radius: 100%;
    background-color: #f5f6f7;
    color: #8b95a5;
    text-transform: uppercase;
	margin-top: 40px;
	margin-left: 20px;
}

.profilAvatarSmall {
	font-size: 16px;
	position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 100%;
    background-color: #f5f6f7;
    color: #8b95a5;
    text-transform: uppercase;
	margin-top: 0px;
	margin-left: 0px;
}
.profilAvatarVerySmall {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
    text-align: center;
    border-radius: 100%;
    background-color: #f5f6f7;
    color: #8b95a5;
    text-transform: uppercase;
	margin-top: 0px;
	margin-left: 0px;
	margin-right: 5px;
}
.profilAvatarVerySmallTXT {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    text-align: center;
    border-radius: 100%;
    background-color: #f5f6f7;
    color: #8b95a5;
    text-transform: uppercase;
	margin-top: 0px;
	margin-left: 0px;
	margin-right: 5px;
}
.icon-stack {
      position: relative;
      display: inline-block;
      width: 2em;
      vertical-align: middle;
    }
    .icon-stack-1x,
    .icon-stack-2x,
    .icon-stack-3x {
      position: absolute;
      left: 0;
      width: 100%;
      text-align: right;
    }
    .icon-stack-1x {
      line-height: inherit;
      left: 5px;
      top: 3px;
      font-size: 8px;
      color: #a0a0a0;
    }
    .icon-stack-2x {
      font-size: 1.5em;
    }
    .icon-stack-3x {
    }

.avatar {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    border-radius: 100%;
    background-color: #f5f6f7;
    color: #8b95a5;
    text-transform: uppercase;
}

.fileUploader {
	margin-top: 20px;
}

.planingPreview {
	background-image: url("/images/raster.png");
}

.planingPreviewPage {
    margin-left: 5px;
    margin-top: 5px; 
    float: left;
    border: 2px solid #999;
    -webkit-box-shadow: 9px 9px 12px -7px rgba(153,153,153,1);
    -moz-box-shadow: 9px 9px 12px -7px rgba(153,153,153,1);
    box-shadow: 9px 9px 12px -7px rgba(153,153,153,1);    
}

.previewBorder {
	max-width:340px;
	max-height:220px;
	min-width:340px;
	min-height:220px;
	background-image: url("/images/raster.png");
	text-align: center;
	vertical-align: bottom;
	margin-top: 20px;
	margin-bottom: 20px;
}

.previewBorderSmall {
	max-width:60px;
	max-height:60px;
	min-width:60px;

	text-align: center;
	vertical-align: bottom;
}

.fileContainer {
	box-shadow: none;
}


.previewSmall {
	
	max-width:50px;
	max-height:50px;
	width: auto;
	height: auto;

}
.previewBig {
	
	max-width:320px;
	max-height:200px;
	width: auto;
	height: auto;

}

.buttonIcon {
    outline: 0px !important;
    min-width: 24px !important;
    border-radius: 20px !important;
}

.smallPadding {
    padding-left: 10px;
}

.modalBody {
    min-height: 400px;
    max-height: 400px;
    overflow: scroll;
}

.fa-actice {
    color: rgb(239, 83, 80);
}

.showLink {
    cursor: pointer;
}

.vivaGridBody {
    min-height: 525px;
}

.gridBottom {
    background: #aaa;
    color: rgb(228, 231, 234);
    line-height: 42px;
    padding: 3px;
}

.grid-Row:hover .grid-Cell {
    background: #f5f6f7 !important;
}

.maskField_50 {
    min-width: 50px !important;
    max-width: 50px !important;
}
.maskField_100 {
    min-width: 100px !important;
    max-width: 100px !important;
}
.maskField_150 {
    min-width: 150px !important;
    max-width: 150px !important;
}

.maskField_200 {
    min-width: 200px !important;
    max-width: 200px !important;
}
.maskField_250 {
    min-width: 250px !important;
    max-width: 250px !important;
}

.maskField_300 {
    min-width: 300px !important;
    max-width: 300px !important;
}

.maskField_400 {
    min-width: 400px !important;
    max-width: 400px !important;
}

.maskField_500 {
    min-width: 500px !important;
    max-width: 500px !important;
}

.maskFieldRow {

}

.MuiTypography-body1 {
	font-weight: bold !important;
}

.grid-Cell {
    background-color: #fff;
    padding-left: 8px;
    padding-right: 8px;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #ddd;
    width:240px;
    min-height:35px;
    display: inline-grid;
}

.grid-Cell:not(.editing):not(.rdg-child-cell) .grid-Cell__value, .grid-Cell:not(.editing):not(.rdg-child-cell) .grid-Cell__header {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}


.grid-Cell:not(.editing) .grid-Cell__value, .grid-Cell:not(.editing) .grid-Cell__header {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.grid-Cell-Header { 
    background: #aaa;
    color: #eee;
}

.grid-Cell__value ul {
    margin-top: 5px;
}



.grid-Cell__value a, .grid-Cell__value a:hover {
    color: #000;
}

.alignItems{
	align-items: flex-start !important;
}

.rowpadding {
	padding: 10px;
}

.Vivacard {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #c8ced3;
    border-radius: .25rem;
}

.Vivacardheader {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: #f0f3f5;
    border-bottom: 1px solid #c8ced3;
}

.Vivacardbody {
    flex: 1 1 auto;
    overflow-x: scroll;
    height: auto;
}

.BreadCrumb {
    background-color: #1976d2 !important;
    height: 24px !important;
    margin-left: 6px !important;
}

.border {
    border: 1px solid red;
   
}

.gridIcon {
    line-height: 30px;
    min-width: 20px;
}

.bg-img {
    background-image: url(/images/maxresdefault.jpg);
    position: relative;
    border-bottom: none;
    background-position: center;
    -webkit-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
}

.min-h-fullscreen {
    min-height: 100vh !important;
}

.py-30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
}

.px-80 {
    padding-right: 80px !important;
    padding-left: 80px !important;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5c6873;
    text-align: center;
    white-space: nowrap;
    background-color: #f0f3f5;
    border: 1px solid #e4e7ea;
    border-radius: .25rem;
}

.displayThis {
	display: block;
}

.hideThis {
	display: none;
}

.clearmargin {
    margin: 0px !important;
}

.maxWidth {
	flex: 1;
	padding: 10px;
}

.appBar {
	position: relative !important;
	max-height: 64px;
  }

.title {
    flex: 1;
  }

button {
    outline: 0px !important;
}